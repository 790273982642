import { GoogleInitOptions } from '@abacritt/angularx-social-login';
import { isDevMode } from '@angular/core';
import { environment } from '@config/environments/environment';


// Each project have diffrent appId - you only need to change appId at this const.
/// Default appID;
export const APP_ID_FOR_PROJECT = environment.authen.appKey;

export const LINK_NAVIGATE_TO_POLICY_WHEN_REGISTER = '';

// 5 lần login failure -> enable capcha
export const LOGIN_COUNT_ERROR_LIMIT_TO_ENABLE_CAPCHA = 5;

// Kích hoạt chức năng đăng nhập bằng google.
export const ENABLE_LOGIN_BY_GOOGLE = isDevMode();
export const LOGIN_GOOGLE_AUTH_CLIENT_ID = environment.authen.googleAuthClientID
export const LOGIN_GOOGLE_OPTIONS: GoogleInitOptions = {
  prompt: 'select_account',
  oneTapEnabled: false,
  scopes: ['email'],
};

// Kích hoạt chức năng đăng nhập bằng facebook;
export const ENABLE_LOGIN_BY_FACEBOOK = isDevMode();
export const LOGIN_FACEBOOK_SECRET_KEY = environment.authen.facebookAuthSecretKey;
export const LOGIN_FACEBOOK_OPTIONS = {
  scope:
    'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  return_scopes: true,
  enable_profile_selector: true,
};

// Kích hoạt chức năng thay đỗi mật khẩu
export const ENABLE_CHANGE_PASSWORD = true;

// Kích hoạt chức năng thay đỗi địa chỉ email
export const ENABLE_CHANGE_EMAIL = isDevMode();

// Kích hoạt chức năng xác minh lần đầu khi đăng nhập
export const ENABLE_VERIFY_AFTER_LOGIN_FIRST_TIME = !isDevMode();

// Kích hoạt chức năng yêu cầu bổ xung thông tin để xử dụng ứng dụng;
export const ENABLE_REQUIRE_UPDATE_INFO = !isDevMode();

export const ENABLE_CHOOSE_ACCOUNT_TYPE = true;
// DEFAULT ACCOUNT TYPE.
export const E_ACCOUNT_TYPE = {
  NHAN_VIEN: 'NHAN_VIEN',
  SINH_VIEN: 'SINH_VIEN'
}

export const ACCOUNT_TYPES = [
  {
    id: E_ACCOUNT_TYPE.NHAN_VIEN,
    key: 'nv',
    displayText: 'auth.component.login.form.staffAccounType',
    enableLanguage: true,
    show: true,
    default: true,
  },
  {
    id: E_ACCOUNT_TYPE.SINH_VIEN,
    key: 'sv',
    displayText: 'auth.component.login.form.studentAccountType',
    enableLanguage: true,
    show: true,
    default: false,
  },
];


export const API_VERSIONs = {
  VERSION_1_0_0: '1.0.0',
  VERSION_2_0_0: '2.0.0',
};

export enum AuthVersion {
  VERSION_1, // Phan quyen cu
  VERSION_2, // phan quyen moi
}

// Storage key.
export const SESSION_STORAGE_KEYS = {
  // Session storage:
  CALLBACK_URL: '___core_feature.authenticate.access_token_guard.callback_url',
  CURRENT_REQUIREMENT_AFTER_LOGIN: '___core_feature.authenticate.require_udpate_auth_info_after_login_first_time',
  LOGIN_ERROR_RECAPTCHA_COUNT: '___core_feature.authenticate.login_default.login_error_recaptcha_count',

  // Localstorage:
  ACCESS_TOKEN: '___core_feature.authenticate.access_token',
  LIST_ACTION_ACTIVE: '___core_feature.authenticate.list_action_active',
  USER_INFORMATION: '___core_feature.authenticate.user_information',
  REQUIRE_UPDATE_MORE_INFO_AFTER_ENTER_APP: '___core_feature.authenticate.require_update_more_info_after_enter_app',

  // Event emiter
  AUTH_EVENT: '___core_feature.authenticate.events',
}

// Upgrade login with token 
export const URI_APIS_VERSION_1 = {
  LOGIN: '/login',
  LOGIN_BY_TOKEN: '/login-token'
}

// Apis VERSION 2
export const URI_APIs = {
  LOGIN: `/authen/user/enter-system/login-normal`,
  LOGIN_BY_TOKEN: `/authen/user/enter-system/login-token`,
  LOGIN_BY_THIRD_PARTY: `/authen/user/enter-system/login-th3`,
  LOGOUT: `/authen/user/enter-system/logout`,
  REGISTER: '/register',

  // Login 3th
  LOGIN_BY_GOOOGE: '/authen/user/enter-system/google',
  LOGIN_BY_FACEBOOK: '/authen/user/enter-system/facebook',


  FORGOT_PASSWORD: '/authen/user/require/forgot-pass/email',
  FORGOT_PASSWORD_HINT_EMAIL: '/authen/user/require/forgot-pass/email/get',

  // Verify account first login
  VERIFY_FIRST_LOGIN_GET_REQUIRE: '/authen/user/require/get-requirement',

  VERIFY_FIRST_LOGIN_EMAIL_ADDRESS: '/authen/user/require/first-login/update-email',

  VERIFY_FIRST_LOGIN_EMAIL_PHONE: '/authen/user/enter-system/verify-email',
  VERIFY_FIRST_LOGIN_GET_HINT_PHONE_NUMBER:
    '/authen/user/enter-system/verify-email',

  VERIFY_FIRST_LOGIN_CHANGE_DEFAULT_PASSWORD:
    '/authen/user/require/first-login/change-password',

  VERIFY_FIRST_LOGIN_CONFIRM_OTP: '/authen/user/flow-code/verify?code=',

  VERIFY_DEVICE: '/authen/user/require/verify-device',

  // Profile:
  PROFILE_UPDATE: `/authen/user/info/update`,
  PROFILE_GET: '/authen/user/info/get',
  PROFILE_UPDATE_EMAIL: `/authen/user/update-email`,
  PROFILE_CHANGE_PASSWORD: '/authen/user/change-pass',
  PROFILE_UPLOAD_AVATAR: 'profile/upload-avatar',

  // Update info.
  REQUIRE_UPDATE_INFO_GET: '/authen/user/require/get-requirement',
};

export const PUBLIC_ASSET_PATH = 'assets';
