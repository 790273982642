import { GoogleInitOptions } from "@abacritt/angularx-social-login";
import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Provider } from "@angular/core";
import { AuthVersion } from "./const";

export interface ISelectAccountType {
  id: string,
  key: string,
  displayText: string,
  enableLanguage: boolean,
  show: boolean,
  default: boolean;
}

export interface IApplicationConf {
  appKey?: string,
  apiVersion?: string, // 1.0.0
  authVersion?: AuthVersion,
  apiVersionHeader?: string, // X-Api-Version
  tokenType?: string,
  authDomain?: string, // http://

  // When token error of exp -> auto navigate to this endpoint. (Check at interceptor)
  useCustomLoginPageURL?: string,

  // Upgrate later: eacth branch have different config.
  branch?: string // HUTECH | UEF | ROYAL
}

export interface IDefaultAuthConf {
  typeAccount?: {
    useDefault: boolean;
    enable?: boolean;
    types?: Array<ISelectAccountType>;
  };
  reCaptcha?: {
    enable?: boolean;
    enableReCaptchaAfterError?: number;
    secretKey?: string;
  };
  register?: {
    enable?: boolean;
    enableReCaptcha?: boolean;
  };
  forgotPassword?: {
    enable?: boolean;
  };
}

export interface ISocialNetworkAuth {
  google?: {
    enable?: boolean,
    loginOptions?: GoogleInitOptions,
    clientId?: string,
  },
  facebook?: {
    enable?: boolean,
    loginOptions?: Object | {
      scope: string,
      return_scopes: boolean,
      enable_profile_selector: boolean
    },
    secretKey?: string,
  },
}


// Social network login

export const SOCIAL_NETWORK_AUTH_CONFIG =
  new InjectionToken<ISocialNetworkAuth>('SOCIAL_NETWORK_AUTH_CONFIG');
export const provideSocialNetworkAuth = (config: Partial<ISocialNetworkAuth> = {}): EnvironmentProviders => {
  const providers: Provider[] = [
    {
      provide: SOCIAL_NETWORK_AUTH_CONFIG,
      useValue: config,
    }
  ];
  return makeEnvironmentProviders(providers);
};


// Default login
export const DEFAULT_AUTH_CONFIG =
  new InjectionToken<IDefaultAuthConf>('DEFAULT_AUTH_CONFIG');
export const provideDefaultAuth = (config: Partial<IDefaultAuthConf> = {}): EnvironmentProviders => {
  const providers: Provider[] = [
    {
      provide: DEFAULT_AUTH_CONFIG,
      useValue: config,
    }
  ];
  return makeEnvironmentProviders(providers);
};

// Default login

export const APPLICATION_CONFIG =
  new InjectionToken<IApplicationConf>('APPLICATION_CONFIG');
export const provideApplicationConfig = (config: Partial<IApplicationConf> = {}): EnvironmentProviders => {
  const providers: Provider[] = [
    {
      provide: APPLICATION_CONFIG,
      useValue: config,
    }
  ];
  return makeEnvironmentProviders(providers);
};



export const GLOBAL_POLICIES =
  new InjectionToken<IApplicationConf>('GLOBAL_POLICIES');
export const provideGlobalPoliciesConfig = (config: Partial<any> = {}): EnvironmentProviders => {
  const providers: Provider[] = [
    {
      provide: GLOBAL_POLICIES,
      useValue: config,
    }
  ];
  return makeEnvironmentProviders(providers);
};
