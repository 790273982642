import { Inject, Injectable } from "@angular/core";
import { APPLICATION_CONFIG, DEFAULT_AUTH_CONFIG, GLOBAL_POLICIES, IApplicationConf, IDefaultAuthConf, ISocialNetworkAuth, SOCIAL_NETWORK_AUTH_CONFIG } from "./core-auth.providers";
import { AuthVersion } from "./const";

@Injectable()
export class CoreAuthConfigService {
  public readonly applicationConfig!: IApplicationConf;
  public readonly socicalNetworkAuthConfig!: ISocialNetworkAuth;
  public readonly defaultAuthConfig!: IDefaultAuthConf;
  public readonly globalPolicies!: object;

  constructor(
    @Inject(APPLICATION_CONFIG) appConfig: IApplicationConf,
    @Inject(SOCIAL_NETWORK_AUTH_CONFIG) social: ISocialNetworkAuth,
    @Inject(DEFAULT_AUTH_CONFIG) _default: IDefaultAuthConf,
    @Inject(GLOBAL_POLICIES) policies: object,
  ) {
    this.applicationConfig = { ...appConfig };
    this.socicalNetworkAuthConfig = { ...social };
    this.defaultAuthConfig = { ..._default };
    this.globalPolicies = { ...policies }
  }

  buildURL(path: string) {
    return this.applicationConfig.authDomain + path;
  }

  isAuthV1() {
    return this.applicationConfig.authVersion == AuthVersion.VERSION_1
  }

  isAuthV2() {
    return this.applicationConfig.authVersion == AuthVersion.VERSION_2
  }
}
